.whyus {
  
    background: url('./image/team.jpg') center center/cover no-repeat; 
     height: 80vh;
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
     object-fit: cover;
   }
   .why_h1 {
    color: white;
   
    font-family: "Open Sans", sans-serif;

    font-size: 3.4em;
    line-height: 1.18em;
}
.why_img {
    width: 20%;
    margin-left: 5%;
    margin-top: 2%;
}
.why_img1{
    width: 17%;
    margin-left: 12%;
    margin-top: 2%;
   
}
.why_img2 {
    width: 20%;
    margin-left: 15%;
    margin-top: 2%;
}
.why_img3 {
    width: 20%;
    margin-left: 30%;
    margin-top: 2%;
}
.why_img4 {
    width: 20%;
    margin-left: 13%;
    margin-top: 2%;
}
.why_square_p{
    width: 62%;
    margin-left: 20%;
    text-align: center;
    font-family: revert;
    font-size: 100%;
    margin-top: 4%;
}

.why_square_p1 {
    width: 71%;
    margin-left: 17%;
    text-align: center;
    font-family: revert;
    font-size: 100%;
    margin-top: -1%;
}
.why_square_p2{
    width: 62%;
    margin-left: 20%;
    text-align: center;
    font-family: revert;
    font-size: 100%;
    margin-top: 4%;
}
.why_square_p3{
    width: 62%;
    margin-left: 20%;
    text-align: center;
    font-family: revert;
    font-size: 100%;
    margin-top: 4%;
}
.why_square_p4{
    width: 71%;
    margin-left: 17%;
    text-align: center;
    font-family: revert;
    font-size: 100%;
    margin-top: 11%;
}
.square_why {
    height: 145px;
    width: 232px;
    background-color: lightgrey;
    margin-left: 18%;
    margin-top: -5.5%;
    position: absolute;
    opacity: 0.9;
}
.square_why1 {
    height: 123px;
    width: 232px;
    background-color: lightgrey;
    margin-left: 49%;
    margin-top: -5.5%;
    position: absolute;
    opacity: 0.9;
}
.square_why2 {
    height: 123px;
    width: 232px;
    background-color: lightgrey;
    margin-left: 83%;
    margin-top: -5.5%;
    position: absolute;
    opacity: 0.9;
}
.square_why3 {
    height: 123px;
    width: 232px;
    background-color: lightgrey;
    margin-left: 74%;
    margin-top: -5.5%;
    position: absolute;
    opacity: 0.9;
}
.square_why4 {
    height: 123px;
    width: 232px;
    background-color: lightgrey;
    margin-left: 26%;
    margin-top: -5.5%;
    position: absolute;
    opacity: 0.9;
}
.why_p
    {
        margin-left: 40%;
        margin-top: 4%;
    
}
@media only screen and (min-width:360px) and (max-width:740px){

    .why_img {
        width: 79%;
        margin-left: 10%;
        margin-top: 4%;
    }
    .why_img1 {
        width: 72%;
        margin-left: 12%;
        margin-top: 38%;
    }
    .square_why1 {
        height: 123px;
        width: 232px;
        background-color: lightgrey;
        margin-left: 17%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img2 {
        width: 69%;
        margin-left: 18%;
        margin-top: 35%;
    }
    .square_why2 {
        height: 102px;
        width: 215px;
        background-color: lightgrey;
        margin-left: 19%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img4 {
        width: 62%;
        margin-left: 15%;
        margin-top: 19%;
    }
    .square_why4 {
        height: 112px;
        width: 178px;
        background-color: lightgrey;
        margin-left: 23%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img3 {
        width: 60%;
        margin-left: 18%;
        margin-top: 31%;
    }
    .square_why3 {
        height: 131px;
        width: 206px;
        background-color: lightgrey;
        margin-left: 19%;
        margin-top: -9.5%;
        position: absolute;
        opacity: 0.9;
    }

}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){


    .why_img {
        width: 35%;
        margin-left: 6%;
        margin-top: 4%;
    }
    .square_why {
        height: 139px;
        width: 216px;
        background-color: lightgrey;
        margin-left: 7%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img1 {
        width: 30%;
        margin-left: 15%;
        margin-top: 4%;
    }
    .square_why1 {
        height: 123px;
        width: 232px;
        background-color: lightgrey;
        margin-left: 65%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
    .square_why {
        height: 139px;
        width: 216px;
        background-color: lightgrey;
        margin-left: 23%;
        margin-top: -7.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img2 {
        width: 40%;
        margin-left: 6%;
        margin-top: 23%;
    }
    .why_img4 {
        width: 50%;
        margin-left: 49%;
        margin-top: -66%;
    }
    .square_why4 {
        height: 107px;
        width: 178px;
        background-color: lightgrey;
        margin-left: 70%;
        margin-top: -12.5%;
        position: absolute;
        opacity: 0.9;
    }
    
    .why_img3 {
        width: 32%;
        margin-left: 38%;
        margin-top: 6%;
    }
    .square_why3 {
        height: 135px;
        width: 203px;
        background-color: lightgrey;
        margin-left: 41%;
        margin-top: -6.5%;
        position: absolute;
        opacity: 0.9;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px){
    .whyus {
        background: url(/static/media/team.822f8980.jpg) center center/cover no-repeat;
        height: 44vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
        object-fit: cover;
    }
    .why_img {
        width: 34%;
        margin-left: 5%;
        margin-top: 2%;
    }
    .square_why {
        height: 136px;
        width: 232px;
        background-color: lightgrey;
        margin-left: 23%;
        margin-top: -8.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img1 {
        width: 29%;
        margin-left: 15%;
        margin-top: 2%;
    }
    .square_why1 {
        height: 123px;
        width: 232px;
        background-color: lightgrey;
        margin-left: 68%;
        margin-top: -8.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img2 {
        width: 29%;
        margin-left: 38%;
        margin-top: 11%;
    }
    .square_why2 {
        height: 95px;
        width: 232px;
        background-color: lightgrey;
        margin-left: 53%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img4 {
        width: 26%;
        margin-left: 5%;
        margin-top: 4%;
    }
    .square_why4 {
        height: 123px;
        width: 232px;
        background-color: lightgrey;
        margin-left: 15%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
    .why_img3 {
        width: 24%;
        margin-left: 23%;
        margin-top: 2%;
    }
    .square_why3 {
        height: 123px;
        width: 232px;
        background-color: lightgrey;
        margin-left: 67%;
        margin-top: -5.5%;
        position: absolute;
        opacity: 0.9;
    }
}